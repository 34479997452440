.error-wrap {
  width: 1200px;
  margin: 0 auto;
  height: calc(100vh - 75px);
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .tip-box{
    width: 100%;
    text-align: center;
  }
  .tip{
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin-top: 10px;
  }
}
