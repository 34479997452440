.payout-form {
  position: relative;
  width: 320px;
  background-color: #f1f4f8;
  padding: 20px 30px;
  flex-shrink: 0;
}
.trade-tip {
  font-family: Montserrat-Bold;
  font-size: 13px;
  color: #0e2c0e;
  margin: 10px 0;
  label {
    font-family: Montserrat-Medium;
    font-size: 15px;
    color: #0e2c0e;
  }
  &.flex-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  .totalAccount {
    font-family: Montserrat-Regular;
    font-size: 20px;
    margin-left: 6px;
    color: #0e2c0e;
    font-weight: normal;
  }
}
.payer-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: #ffffff;
  object-fit: contain;
  img{
    max-width: 128px;
    max-height: 65px;
    object-fit: scale-down;
  }
}
.trade-detail-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  label {
    font-family: Montserrat-Regular;
    font-size: 13px;
    color: #878787;
    margin: 10px 0;
    &::after {
      content: ":";
    }
  }
  span {
    font-family: Montserrat-Regular;
    font-size: 13px;
    color: #0e2c0e;
    margin: 10px 0;
  }
}
.baseline {
  border-width: 0;
  height: 1px;
  background-color: rgba(145, 145, 147, 0.2);
  width: 100%;
}
.payout-btn {
  position: relative;
  height: 50px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #ffffff;
  font-family: DMSans-Bold;
  font-size: 17px;
  border: none;
  border-radius: 4px;
  background-color: #01a27e;
  cursor: pointer;
  &.disabled{
    background-color: #ccc;
    z-index: 100;
  }
}
.goback-box {
  width: 100%;
  display: flex;
  justify-content: center;
  a {
    color: #01a27e;
    font-size: 16px;
    font-weight: bold;
  }
  img {
    object-fit: scale-down;
    margin-top: 5px;
    margin-left: 5px;
  }
}

.checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: Montserrat-Regular;
  font-size: 12px;
  margin-top: 34px;
  .privacy-box {
    margin-left: 6px;
    color: #6a6f7a;
    font-family: Montserrat-Regular;
    font-size: 12px;
  }
  .privacy {
    color: #0376ff;
    cursor: pointer;
  }
}
.privacy-policy-error{
  font-size: 12px;
  font-family: Montserrat-Regular;
  color: #f44336;
  margin-left: 13px;
}

